import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'rwa-issue-credit-note',
  templateUrl: './issue-credit-note.component.html',
  styleUrl: './issue-credit-note.component.scss',
})
export class IssueCreditNoteComponent {
  @Input() dialogVisible: boolean;

  @Output() closeDialog = new EventEmitter<any>();

  formGroup!: FormGroup;

  productList: any = [];

  refundOptions: any[] = [
    { label: 'Full Refund', value: 'full' },
    { label: 'Partial Refund', value: 'partial' },
  ];

  ngOnInit(): void {
    this.intializeForm();
    this.getProductList();
  }

  intializeForm(): void {
    this.formGroup = new FormGroup({
      refundValue: new FormControl('full'),
    });
  }

  getProductList(): void {
    this.productList = [
      {
        name: 'Simple Product 1',
        quantity: 1,
        adjustedPrice: 100.0,
        totalPrice: 100.0,
      },
      {
        name: 'Simple Product 1',
        quantity: 1,
        adjustedPrice: 100.0,
        totalPrice: 100.0,
      },
      {
        name: 'Simple Product 1',
        quantity: 1,
        adjustedPrice: 100.0,
        totalPrice: 100.0,
      },
    ];
  }

  close(): void {
    this.closeDialog.emit();
  }
}
