export const environment = {
  name: 'stg-test',
  userPoolId: 'ap-southeast-1_dIvIKFUHo',
  userPoolClientId: '1rqchtenf9kk53v5hv4q9v3n5j',
  cognitoDomain: 'stg-test-rewaa-admin.auth.ap-southeast-1.amazoncognito.com',
  scopes: [
    'phone',
    'email',
    'openid',
    'profile',
    'aws.cognito.signin.user.admin',
  ],
  redirectSignIn: [`${window.location.origin}/accounts`],
  redirectSignOut: [`${window.location.origin}/login`],
  responseType: 'token',
  launchDarklyClientSideId: '63566d3ef1d7751181713514',
  crmAccountUrl: 'https://crmsandbox.zoho.com/crm/newstaging/tab/Accounts/',
  chargebeeAccountUrl: 'https://rewaa-test.chargebee.com/d/customers/',
  // eslint-disable-next-line global-require, @typescript-eslint/no-var-requires
  appVersion: require('../../package.json').version,
  enableDatadog: false,
  enableFullStory: false,
  datadogApplicationId: 'dummy-application-id',
  datadogClientToken: 'dummy-datadog-client-token',
};
