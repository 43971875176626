import { Injectable } from '@angular/core';
import { Observable, of, switchMap } from 'rxjs';
import { CheckOutFlows } from '@rewaa-team/types';
import {
  BankTransferSubscriptionItems,
  SummaryDTO,
  UpdateSubscriptionItemsDTO,
} from '../../accounts/types';
import { FeatureFlagService } from '../types/feature-flag.service.interface';
import { FeatureFlagEnum } from '../constants/feature-flag.constants';

@Injectable()
export class CheckoutHelperService {
  constructor(private featureFlagService: FeatureFlagService) {}

  isReportToZatcaEnabled(checkoutFlow: CheckOutFlows): Observable<boolean> {
    const featureFlagObservable = this.featureFlagService.isEnabled(
      FeatureFlagEnum.ActivateSubscriptionZatca,
      undefined,
    );

    return featureFlagObservable.pipe(
      switchMap((reportToZatcaEnabled) => {
        if (!reportToZatcaEnabled) return of(false);

        return of(
          checkoutFlow === CheckOutFlows.SubscribeToAPlan ||
            checkoutFlow === CheckOutFlows.UpgradePlan,
        );
      }),
    );
  }

  extractItemsFromSummary(
    summary: SummaryDTO,
    coupons: string[],
  ): UpdateSubscriptionItemsDTO {
    const subscriptionItems: BankTransferSubscriptionItems[] = [];
    const couponIds: string[] = [];
    if (summary.plan) {
      subscriptionItems.push({
        itemPriceId: summary.plan.itemPriceId,
        quantity: summary.plan.quantity,
        originalQuantity: summary.plan.originalQuantity,
        period: summary.plan.period,
        price: +summary.plan.amount.toFixed(2),
        type: summary.plan.type,
        itemLevelDiscount: summary.plan.itemLevelDiscount,
        discountAmount: summary.plan.discountAmount,
        basePlanId: summary.plan.itemId,
      });
    }

    if (summary.location) {
      subscriptionItems.push({
        itemPriceId: summary.location.itemPriceId,
        quantity: summary.location.quantity,
        originalQuantity: summary.location.originalQuantity,
        period: summary.location.period,
        price: +summary.location.amount.toFixed(2),
        type: summary.location.type,
        itemLevelDiscount: summary.location.itemLevelDiscount,
        discountAmount: summary.location.discountAmount,
        basePlanId: summary.location.itemId,
      });
    }

    if (summary.applications) {
      summary.applications.forEach((application) => {
        subscriptionItems.push({
          itemPriceId: application.itemPriceId,
          quantity: application.quantity,
          originalQuantity: application.originalQuantity,
          period: application.period,
          price: +application.amount.toFixed(2),
          type: application.type,
          itemLevelDiscount: application.itemLevelDiscount,
          discountAmount: application.discountAmount,
          basePlanId: application.itemId,
        });
      });

      if (summary.charges) {
        summary.charges.forEach((charge) => {
          subscriptionItems.push({
            itemPriceId: charge.itemPriceId,
            quantity: charge.quantity,
            originalQuantity: charge.originalQuantity,
            period: charge.period,
            price: +charge.amount.toFixed(2),
            type: charge.type,
            itemLevelDiscount: charge.itemLevelDiscount,
            discountAmount: charge.discountAmount,
            basePlanId: charge.itemId,
          });
        });
      }
      if (coupons.length > 0) {
        couponIds.push(...coupons);
      }
    }

    return {
      subscriptionItems,
      nowInTrial: true,
      couponIds,
    };
  }
}
